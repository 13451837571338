<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Funcionarios Frontera</li>
                  <li class="breadcrumb-item active">Tep Voy Por Ti</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Solicitud Nacional</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="crear()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Número Solicitud
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_solicitud"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Usuario
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.user"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_vehiculo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Origen
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.origen"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Destino
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.destino"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in solicitudesN.data" :key="item.id">
                      <td class="text-center">{{ item.id }}</td>
                      <td>{{ item.user.name }}</td>
                      <td>{{ item.tipo_vehiculo.nombre }}</td>
                      <td>{{ item.direccion_origen }}</td>
                      <td>{{ item.direccion_destino }}</td>
                      <td>{{ item.fecha_hora_inicio_servicio }}</td>
                      <td>{{ item.fecha_hora_fin_servicio }}</td>
                      <td class="text-center">
                        <span class="badge badge-warning"
                          :class="
                            item.estado == 1 ?
                            'badge-warning' :
                            item.estado == 2 ?
                            'badge-success' :
                            item.estado == 3 ?
                            'badge-info' :
                            item.estado == 4 ?
                            'badge-danger' :
                            item.estado == 5 ?
                            'badge-primary' :
                            item.estado == 6 ?
                            'badge-success' :
                            item.estado == 7 ?
                            'badge-danger' :
                            'badge-secundary' 
                          "
                        >
                          {{ item.nEstado }}
                        </span>
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-purple"
                            style="cursor: pointer"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-tepsolicitud"
                            @click="llenarModal(item)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudesN.total">
                  <p>
                    Mostrando del <b>{{ solicitudesN.from }}</b> al
                    <b>{{ solicitudesN.to }}</b> de un total:
                    <b>{{ solicitudesN.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudesN"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepSolicitudesDetalle ref="TepSolicitudesDetalle" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TepSolicitudesDetalle from "../../../tep/internos/solicitudes/TepSolicitudDetalle";
export default {
  name: "FuncionarioSolicitudNacionalesIndex",
  components: {
    Loading,
    pagination,
    TepSolicitudesDetalle,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id_solicitud: null,
        origen: null,
        destino: null,
        estado: null,
        fecha_inicial: null,
        fecha_final: null,
      },
      solicitudesN: {},
      listasForms: {
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/funcionariosFrontera/solicitudNacional?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitudesN = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/84").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    llenarModal(item) {
      this.$refs.TepSolicitudesDetalle.llenar_modal(item);
    },

    crear() {
      return this.$router.push({
        name: "/FuncionariosFrontera/solicitudNacionalForm",
        params: {
          accion: "Crear",
          id: 0,
        },
      });
    },

    edit(solicitudNacional) {
      return this.$router.push({
        name: "/FuncionariosFrontera/solicitudNacionalForm",
        params: {
          accion: "Editar",
          data_edit: solicitudNacional,
          id: solicitudNacional.id,
        },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getMsg();
  },
};
</script>
